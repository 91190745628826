/* eslint-disable no-undef */
import { getApp, getApps, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// if (!process.env.FIREBASE_PRIVATE_KEY) {
//   throw new Error("FIREBASE_PRIVATE_KEY is not defined");
// }

// if (!process.env.FIREBASE_AUTH_DOMAIN) {
//   throw new Error("FIREBASE_AUTH_DOMAIN is not defined");
// }

// if (!process.env.FIREBASE_PROJECT_ID) {
//   throw new Error("FIREBASE_PROJECT_ID is not defined");
// }

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  // databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  // storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  // messagingSenderId: "394316237820",
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
};

const app = getApps().length > 0 ? getApp() : initializeApp(firebaseConfig);
export default app;
const auth = getAuth(app);
// const provider = new GoogleAuthProvider();

export { auth };
