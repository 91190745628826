import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "./firebase";

export const signInWithPassword = async (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const sendPasswordReset = async (email: string) => {
  return sendPasswordResetEmail(auth, email);
};

export const createUserWithEmailPassword = async (
  email: string,
  password: string,
) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

export const sendEmailVerificationLink = async () => {
  if (!auth.currentUser) throw new Error("No user signed in");
  let user = auth.currentUser;
  return sendEmailVerification(user);
};

export const signOut = async () => {
  return auth.signOut();
};
