"use client";

import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
  useMemo,
} from "react";
import { onAuthStateChanged, User } from "firebase/auth";
import { auth } from "../lib/firebase";
import { setPersistence, browserLocalPersistence } from "@firebase/auth";
import { signOut } from "../lib/auth";

setPersistence(auth, browserLocalPersistence);

interface IAuthContext {
  user: User | null;
  loading: boolean;
  logOut: () => Promise<void>;
}

export const AuthContext = createContext<IAuthContext>({
  user: null,
  loading: true,
  logOut: () => Promise.resolve(),
});

export const useAuthContext = () => useContext(AuthContext);

interface AuthContextProviderProps {
  children: ReactNode;
  // Might need a callback function here that allows us to set the cookie
}

const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }

      setLoading(false);
      return () => unsubscribe();
    });
  }, []);

  const logOut = async () => {
    await signOut();
    setUser(null);
  };

  const value = useMemo(
    () => ({
      user,
      loading,
      logOut,
    }),
    [user, loading, logOut],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
