"use client";

import { ApolloLink, from, HttpLink } from "@apollo/client";
import {
  ApolloNextAppProvider,
  InMemoryCache,
  ApolloClient,
  SSRMultipartLink,
} from "@apollo/experimental-nextjs-app-support";

import { setContext } from "@apollo/client/link/context";
import { getAuthToken } from "app/actions";

const makeClient = () => {
  const authLink = setContext(async (_, { headers }) => {
    const token = await getAuthToken();

    if (token) {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
          ...headers,
        },
      };
    }

    return {
      headers: {
        ...headers,
      },
    };
  });

  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
  });

  const link = from([authLink, httpLink]);

  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            possibleGhostPlayers: {
              keyArgs: false,
              merge(_existing, incoming) {
                return incoming;
              },
            },
            clubRounds: {
              keyArgs: ["filter"],
              merge(existing = {}, incoming, { args }) {
                const skip = args?.filter?.skip ?? 0;

                // Create a shallow copy of the existing rounds array
                const merged = existing?.rounds ? existing.rounds.slice(0) : [];

                // Merge incoming rounds with existing ones
                for (let i = 0; i < incoming.rounds.length; ++i) {
                  merged[skip + i] = incoming.rounds[i];
                }

                return {
                  ...incoming,
                  rounds: merged,
                };
              },
              read(existing, { args }) {
                const skip = args?.filter?.skip ?? 0;
                const take = args?.filter?.take ?? existing?.rounds.length;

                if (existing?.rounds) {
                  return {
                    ...existing,
                    rounds: existing.rounds.slice(skip, skip + take),
                  };
                }

                return existing;
              },
            },
          },
        },
        RoundConnection: {
          fields: {
            rounds: {
              keyArgs: false,
              merge(_existing, incoming) {
                return incoming;
              },
            },
          },
        },
        Team: {
          fields: {
            userRounds: {
              keyArgs: false,
              merge(_existing, incoming) {
                return incoming;
              },
            },
          },
        },
        Round: {
          fields: {
            tickerItems: {
              keyArgs: false,
              merge(_existing, incoming) {
                return incoming;
              },
            },
            settings: {
              keyArgs: false,
              merge(existing, incoming) {
                return {
                  ...existing,
                  ...incoming,
                };
              },
            },
          },
        },
        CoursePerformance: {
          fields: {
            rounds: {
              keyArgs: false,
              merge(_existing, incoming) {
                return incoming;
              },
            },
          },
        },
      },
    }),
    link:
      typeof window === "undefined"
        ? ApolloLink.from([
            new SSRMultipartLink({
              stripDefer: true,
            }),
            link,
          ])
        : link,
  });
};
export function ApolloWrapper({ children }: React.PropsWithChildren) {
  return (
    <ApolloNextAppProvider makeClient={makeClient}>
      {children}
    </ApolloNextAppProvider>
  );
}
